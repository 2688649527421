import { css } from "@emotion/react"

export const globalCss = css`
  .columns.is-fullheight {
    min-height: 100vh;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1.2rem;
  }

  h2 {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }

  .Toastify__toast-body a {
    color: inherit;
    text-decoration: underline;
  }
`
