import React from "react"
import styled from "@emotion/styled"
import { BlankLayout } from "~/layouts/blank/BlankLayout"
import { MomentoHeader } from "~/layouts/momento/MomentoHeader"

interface MomentoLayoutProps {
  children: any
  logoLink?: string
}

export function MomentoLayout(props: MomentoLayoutProps) {
  return (
    <BlankLayout>
      <MomentoHeader logoLink={props.logoLink} />
      <Main>{props.children}</Main>
    </BlankLayout>
  )
}

const Main = styled.main`
  padding: 24px;
`
