import React from "react"
import "./global/bulma.scss"
import { Global } from "@emotion/react"
import { WithChildren } from "~/types/reactTypes"
import { globalCss } from "./global/global.css"

interface BaseLayoutProps extends WithChildren {}

function BaseLayout(props: BaseLayoutProps) {
  return (
    <>
      <Global styles={globalCss} />
      {props.children}
    </>
  )
}

export default BaseLayout
