import React from "react"
import BaseLayout from "~/layouts/BaseLayout"
import { css, Global } from "@emotion/react"

interface BlankLayoutProps {
  children: any
}

const resetCss = css`
  body,
  html {
    background-color: white;
  }
`

export function BlankLayout(props: BlankLayoutProps) {
  return (
    <BaseLayout>
      <Global styles={resetCss} />
      {props.children}
    </BaseLayout>
  )
}
