import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import logo from "./header_logo.png"

interface MomentoHeaderProps {
  logoLink?: string
}

export function MomentoHeader(props: MomentoHeaderProps) {
  return (
    <Header>
      <Link to={props.logoLink || "/momento/events"}>
        <img src={logo} alt="Logo" />
      </Link>
    </Header>
  )
}

const Header = styled.header`
  background-color: #343a40;
  padding: 0.5rem 24px;
  img {
    margin-left: -7px;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
`
